
import { FC, useMemo } from "react";
import DataTable from "./DataTable";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import { translatePaymentTypes } from "utils/convertPaymentTypes";
import { PaymentMethodEntity } from "api/generated";

interface DataTableProps {
    selectedData: PaymentMethodEntity[];
    setData: (data: PaymentMethodEntity[]) => void;
    paymentMethods: PaymentMethodEntity[];
}

export interface EmployeeOptions {
    employeeId: number;
    room: boolean;
    position: boolean;
    status: boolean;
}


const PaymentMethodDataTable: FC<DataTableProps> = (props) => {
    const { t } = useTranslation();


    const getColumnShape = (data: PaymentMethodEntity[]) => {
        return [
            {
                Header: t('menu.apps.paymentMethods'),
                Cell: ({ row }: any) => {
                    return translatePaymentTypes(row.original.type, t);
                }
            },
            {
                Header: ' ',
                Cell: ({ row }: any) => {

                    const isSelected = props.selectedData?.find(value => value.id === row.original.id);
                    return (
                        <Checkbox
                            key={row.original}
                            defaultChecked={!!isSelected}
                            onClick={(e) => {
                                const newSelectedPayments = isSelected ?
                                    props.selectedData?.filter(method => method.id !== row.original.id) :
                                    props.selectedData.concat([row.original]);
                                props.setData(newSelectedPayments);
                            }}
                        />
                    )
                }
            }
        ];
    };





    const columns: any = useMemo(() => getColumnShape(props.paymentMethods), [props.selectedData, t]);
    return DataTable({
        data: props.paymentMethods,
        columns,
        notSelectableRows: true
    });
};

export default PaymentMethodDataTable;