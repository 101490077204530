import AddTurnoverModal from "page-sections/data-table/dataTableV2/AddTurnoverModal";
import { FC, useEffect, useState } from "react"
import { DailyNoteEntity, DepartmentEntity, DepositEntity, PaymentMethodEntity, SectionEntity, TurnoverItemEntity } from "api/generated";
import { Button, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { dailyNotesApi, departmentsApi, depositsApi, paymentMethodsApi, paymentSectionsApi, turnoverApi } from "api";
import FlexBox from "components/flexbox/FlexBox";
import { Add, ArrowDownward, ArrowUpward, CheckBox, CheckBoxOutlineBlank, Info } from "@mui/icons-material";
import CalendarInput from "components/input-fields/CalendarInput";
import TurnoverDataTable from "page-sections/data-table/TurnoverDataTable";
import SelectSectionModal from "page-sections/data-table/dataTableV2/SelectSectionModal";
import { useSeason } from "contexts/SeasonContext";
import AddDepositModal from "page-sections/data-table/dataTableV2/AddDepositModal";
import SearchInput from "components/input-fields/SearchInput";
import { endOfDay, startOfDay } from "date-fns";
import { checkTokenRole } from "utils/checkToken";
import DefaultUserImage from '../../assets/images/defaultUserPage.svg';
import Logo from '../../assets/images/logo_onestaff.png';
import { Small } from "components/Typography";
import { translatePaymentTypes } from "utils/convertPaymentTypes";

const TurnoverSummary: FC = () => {
    const [openAddTurnoverModal, setOpenAddTurnoverModal] = useState(false);
    const [openAddDepositModal, setOpenAddDepositModal] = useState(false);
    const [openSelectSectionModal, setOpenSelectSectionModal] = useState(false);

    // To set if add deposit modal is opened from table or from button
    const [addMode, setAddMode] = useState(true);
    // To store the default date for depositModal when it's opened from the table.
    const [depositsDate, setDepositDate] = useState(new Date());

    const { seasonId } = useSeason();

    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<DepartmentEntity>()
    const [sections, setSections] = useState<SectionEntity[]>([]);
    const [selectedSection, setSelectedSection] = useState<SectionEntity>();

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);
    const [turnoverItems, setTurnoverItems] = useState<TurnoverItemEntity[]>();
    const [deposits, setDeposits] = useState<DepositEntity[]>();
    const [dailyNotes, setDailyNotes] = useState<DailyNoteEntity[]>([]);

    const [endDate, setEndDate] = useState(new Date());
    const [isEndDateValid, setIsEndDateValid] = useState(true);
    const [startDate, setStartDate] = useState(() => new Date(new Date().setDate(new Date().getDate() - 6)));
    const [isStartDateValid, setIsStartDateValid] = useState(true);
    const [isEndBeforeStart, setIsEndBeforeStart] = useState(false);

    const [searchValue, setSearchValue] = useState("");

    const [ascSort, setAscSort] = useState(true);
    const [showAllMethods, setShowAllMethods] = useState(true);

    const role = checkTokenRole();

    const theme = useTheme();
    const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchData();
    }, [seasonId]);

    useEffect(() => {
        fetchSections();
    }, [departments])

    const fetchData = () => {
        fetchPaymentMethods();
        fetchDepartments();
        fetchSections();
        fetchTurnoverItems();
        fetchDeposits();
        fetchDailyNotes();
    }

    const reverseData = () => {
        setSections([...sections].reverse())
        setDepartments([...departments].reverse())
        setPaymentMethods([...paymentMethods].reverse())
    }

    useEffect(() => {
        reverseData();
    }, [ascSort])

    const fetchPaymentMethods = () => {
        // Everybody can see payment methods
        paymentMethodsApi.findAll().then(({ data }) => {
            if (ascSort) {
                data.sort((a, b) => {
                    return translatePaymentTypes(a.type, t).toLowerCase().localeCompare(translatePaymentTypes(b.type, t).toLowerCase())
                })
                setPaymentMethods(data);

            } else {
                data.sort((a, b) => {
                    return -translatePaymentTypes(a.type, t).toLowerCase().localeCompare(translatePaymentTypes(b.type, t).toLowerCase())
                })
                setPaymentMethods(data);

            }
        })
    }

    const fetchDepartments = () => {
        if (role !== 'USER' && role !== 'MANAGER') {
            departmentsApi.findByRole(seasonId).then(({ data }) => {
                if (ascSort) {
                    data.sort((a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())

                    })
                } else {
                    data.sort((a, b) => {
                        return -a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    })
                }
                setDepartments(data);
            })
        }
    }

    const fetchSections = () => {
        if (role !== 'USER' && role !== 'MANAGER') {
            paymentSectionsApi.findAll(seasonId).then(async ({ data }) => {
                if (ascSort) {
                    data.sort((a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    })
                } else {
                    data.sort((a, b) => {
                        return -a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    })
                }
                setSections(data);
            })
        }
    }

    const fetchTurnoverItems = () => {
        if (role === 'OWNER' || role === 'CONTROLLER') {
            turnoverApi.findAll().then(({ data }) => {
                setTurnoverItems(data);
            })
        }
    }

    const fetchDeposits = () => {
        if (role === 'OWNER') {
            depositsApi.findAll().then(({ data }) => {
                setDeposits(data);
            })
        }
    }

    const fetchDailyNotes = () => {
        if (role === 'OWNER' || role === 'CONTROLLER') {
            dailyNotesApi.findAll().then(({ data }) => {
                setDailyNotes(data);
            })
        }
    }


    const handleOpenAddTurnoverModal = () => {
        if (sections.length === 1) {
            setSelectedSection(sections[0]);
            setOpenAddTurnoverModal(true);
        } else if (departments.length === 1) {
            setSelectedDepartment(departments[0]);
            const filteredSectionsByDepartment = sections.filter((s) => { return s.departmentId === departments[0].id });
            if (filteredSectionsByDepartment.length === 1) {
                setSelectedSection(filteredSectionsByDepartment[0]);
                setOpenAddTurnoverModal(true);
            } else {
                setOpenSelectSectionModal(true);
            }
        } else {
            setOpenSelectSectionModal(true);
        }
    }

    return (
        <>
            {
                (role === 'OWNER' || role === 'DEPCHIEF') &&
                <>
                    <FlexBox marginTop={1} justifyContent={'end'} maxHeight={60}>
                        {
                            (departments.length <= 0 || sections.length <= 0) &&
                            <Small color={theme.palette.warning.main} marginRight={2} width={300}>{t('turnovers.cannotAdd')}</Small>
                        }
                        {role === 'OWNER' && <Button
                            sx={{ marginRight: 1 }}
                            variant="contained"
                            disabled={departments.length <= 0 || sections.length <= 0}
                            startIcon={<Add />}
                            onClick={() => {
                                setAddMode(true);
                                setOpenAddDepositModal(true);
                            }}>{t('deposit.add')}
                        </Button>
                        }
                        <Button
                            variant="contained"
                            disabled={departments.length <= 0 || sections.length <= 0}
                            startIcon={<Add />}
                            onClick={() => { handleOpenAddTurnoverModal() }}>
                            {t('amount.add')}
                        </Button>
                    </FlexBox>

                    <SelectSectionModal
                        open={openSelectSectionModal}
                        onClose={() => { setOpenSelectSectionModal(false); setSelectedDepartment(undefined); setSelectedSection(undefined); fetchData() }}
                        onConfirm={() => {
                            setOpenAddTurnoverModal(true);
                            setOpenSelectSectionModal(false)
                        }}
                        setSelectedSection={(section: SectionEntity | undefined) => { setSelectedSection(section) }}
                        sections={sections}
                        selectedSection={selectedSection}
                        setSelectedDepartment={(department: DepartmentEntity | undefined) => { setSelectedDepartment(department) }}
                        departments={departments}
                        selectedDepartment={selectedDepartment}
                    />

                    <AddTurnoverModal
                        open={openAddTurnoverModal}
                        section={selectedSection}
                        paymentMethods={paymentMethods}
                        isOwner={role === 'OWNER'}
                        onClose={() => { setOpenAddTurnoverModal(false); setSelectedDepartment(undefined); setSelectedSection(undefined); fetchData() }}
                        onBack={() => {
                            setOpenAddTurnoverModal(false);
                            const filteredSectionsByDepartemnt = sections.filter((s) => { return s.departmentId === departments[0].id });
                            if (sections.length === 1 || departments.length === 1 && filteredSectionsByDepartemnt.length === 1) {
                                setSelectedDepartment(undefined);
                                setSelectedSection(undefined);
                                fetchData();
                            } else {
                                setOpenSelectSectionModal(true);
                            }
                        }}
                    />
                    {role === 'OWNER' && <AddDepositModal
                        open={openAddDepositModal}
                        onClose={() => { setOpenAddDepositModal(false); fetchData() }}
                        sections={sections}
                        departments={departments}
                        addMode={addMode}
                        defaultDate={depositsDate}
                    />}
                </>
            }

            {
                role === 'DEPCHIEF' && <Container maxWidth="lg" sx={{ height: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
                    {mobileBreakpoint && (
                        <img src={Logo} alt="OneStaff Logo" style={{ width: '80px', height: 'auto', position: 'absolute', top: 20, left: '50%', transform: 'translateX(-50%)' }} />
                    )}

                    <Grid container sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 2 : 1 }}>
                            <img src={DefaultUserImage} style={{ width: '100%', height: '90%' }} />
                        </Grid>
                    </Grid>
                    {!mobileBreakpoint && (
                        <img src={Logo} alt="OneStaff Logo" style={{ width: '120px', height: 'auto', position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)' }} />
                    )}
                </Container>
            }

            {
                (role === 'OWNER' || role === 'CONTROLLER') && <Grid container>
                    <Grid item xs={12} md={2} marginTop={2} marginBottom={2} marginRight={1}>
                        <SearchInput
                            bordered={false}
                            placeholder={t('common.tables.button.searchItems', {
                                items: t('menu.apps.sections'),
                            })}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} md={2} marginTop={1} marginBottom={2} marginRight={1}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            value={startDate}
                            onChange={(newValue) => {
                                if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                                    setStartDate(newValue);
                                    setIsStartDateValid(true);
                                    if (+startOfDay(newValue) > +endOfDay(endDate)) {
                                        setIsEndBeforeStart(true);
                                    } else {
                                        setIsEndBeforeStart(false);
                                    }
                                }
                                else
                                    setIsStartDateValid(false);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: (!isStartDateValid && t('date.error')),
                                    error: !isStartDateValid || isEndBeforeStart
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} md={2} marginTop={1} marginBottom={2} marginRight={1}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            value={endDate}
                            onChange={(newValue) => {

                                if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                                    setEndDate(newValue);
                                    setIsEndDateValid(true);
                                    if (+endOfDay(newValue) < +startOfDay(startDate)) {
                                        setIsEndBeforeStart(true);
                                    } else {
                                        setIsEndBeforeStart(false);
                                    }
                                }
                                else
                                    setIsEndDateValid(false);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: !isEndDateValid && t('date.error') || (isEndBeforeStart && t('date.endBeforeStart')),
                                    error: !isEndDateValid || isEndBeforeStart
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} justifyContent={"start"}>
                        <Button style={{ marginBottom: 20, marginRight: 50 }} startIcon={ascSort ? <ArrowDownward /> : <ArrowUpward />} onClick={() => { setAscSort(!ascSort) }}>
                            {ascSort ? "ABC" : "CBA"}
                        </Button>
                        <Button
                            style={{ marginBottom: 20, marginRight: 20 }}
                            startIcon={showAllMethods ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            onClick={() => { setShowAllMethods(!showAllMethods) }}
                        >
                            {t('turnover.show')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4} justifyContent={"end"}>
                        <Button
                            fullWidth
                            style={{ marginBottom: 20 }}
                            startIcon={<Info />}
                            sx={{
                                textAlign: 'start'
                            }}
                        >
                            {t('turnover.infoEdit')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TurnoverDataTable
                            startDate={startDate}
                            endDate={endDate}
                            turnoverItems={turnoverItems}
                            departments={departments}
                            sections={sections}
                            paymentMethods={paymentMethods}
                            deposits={deposits}
                            dailyNotes={dailyNotes}
                            fetchData={fetchData}
                            searchValue={searchValue}
                            showEmpty={showAllMethods}
                            openDepositModal={(date: Date) => {
                                setDepositDate(date);
                                setAddMode(false);
                                setOpenAddDepositModal(true);
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default TurnoverSummary;