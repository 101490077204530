import { Autocomplete, Button, FormControlLabel, Grid, Switch, TextField, styled } from "@mui/material";
import { CreatePaymentMethodDto, PaymentMethodEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2, H6 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import { t } from "i18next";
import { FC } from "react";
import { translatePaymentTypes } from "utils/convertPaymentTypes";
import * as Yup from 'yup';

interface ModalProps {
    data?: PaymentMethodEntity;
    open: boolean;
    onClose: () => void;
    onSubmitAction: (segment: CreatePaymentMethodDto) => void;
    avaiblePaymentTypes: Array<{ value: string, label: string }>;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddPaymentMethodModal: FC<ModalProps> = (
    {
        data,
        open,
        onClose,
        onSubmitAction,
        avaiblePaymentTypes
    }

) => {

    const initialValues = {
        type: data?.type ?? '' as any,
        description: data?.description ?? '',
        depositable: data?.depositable ?? false,
    };

    const fieldValidationSchema = Yup.object().shape({
        type: Yup.string().required(
            t('common.forms.field.required', {
                field: t('departments.name'),
            }),
        ),
    });

    const { setFieldValue, values, errors, handleChange, handleSubmit, touched, resetForm } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: fieldValidationSchema,
        onSubmit: (values) => {
            onSubmitAction(values);
            onClose();
            resetForm();
        },

    });

    const handleClose = () => {
        onClose();
        resetForm();
    }



    return (
        <StyledAppModal
            open={open}
            handleClose={handleClose}
        >

            <H2 mb={2}>
                {
                    data === undefined ? t('common.forms.addItemLabel', {
                        item: t('amountType.item'),
                    }) : t('common.forms.updateItemLabel', {
                        item: t('amountType.item')
                    })
                }
            </H2>
            <Grid container alignItems={'center'}>
                <Grid item xs={6}>
                    {
                        data ? (
                            <>
                                <H6 mb={1}>{t('departments.name')}</H6>
                                <AppTextField
                                    fullWidth
                                    size='small'
                                    name='name'
                                    placeholder={t('departments.name')}
                                    value={translatePaymentTypes(values.type, t)}
                                    disabled={true}
                                    onChange={handleChange}
                                />
                            </>
                        )
                            :
                            (
                                <Autocomplete
                                    options={avaiblePaymentTypes}
                                    disableClearable
                                    onChange={(e, value) => {
                                        setFieldValue('type', value?.value?.toUpperCase());
                                    }}
                                    renderInput={(params: any) =>
                                        <TextField
                                            name="type"
                                            {...params}
                                            label={t('departments.name')}
                                            error={Boolean(errors.type && touched.type)}
                                            helperText={(touched.type && errors.type) as string}
                                        />
                                    }


                                />
                            )
                    }

                </Grid>
                <Grid item xs={5} sx={{ ml: 1 }}>
                    <H6 mb={1}>{t('paymentMethod.depositable')}</H6>
                    <FormControlLabel control={
                        <Switch
                            checked={values.depositable}
                            onChange={async () => await setFieldValue('depositable', !values.depositable)} />
                    }
                        label={t('paymentMethod.depositablie')}
                    />
                </Grid>
                <Grid item marginTop={2} xs={12}>
                    <H6 mb={1}>{t('paymentMethod.description')}</H6>
                    <AppTextField
                        fullWidth
                        size='small'
                        name='description'
                        placeholder={t('paymentMethod.description')}
                        value={values.description}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                <Button fullWidth size='small' variant='outlined' onClick={() => {
                    handleClose();
                }}>
                    {t('common.forms.button.cancel')}
                </Button>
                <Button fullWidth size='small' type='submit' variant='contained' onClick={() => { handleSubmit() }}>
                    {t('common.forms.button.save')}
                </Button>
            </FlexBox>

        </StyledAppModal>
    )
}

export default AddPaymentMethodModal;