import { TFunction } from "i18next"


export const translatePaymentTypes = (paymentType: string, t: TFunction<"translation", undefined>) => {
    
    switch(paymentType.toUpperCase()) {
        case "CASH":
            return (t('payment.type.cash'));
        case "DEBITCARD":
            return (t('payment.type.debitcard'));
        case "CREDITCARD":
            return (t('payment.type.creditcard'));
        case "BANCOMAT":
            return 'Bancomat';
        case "CHECK":
            return (t('payment.type.check'));
        case "TRANSFER":
            return (t('payment.type.transfer'));
        case "SATISPAY":
            return 'Satispay'
        case "PAYPAL":
            return 'Paypal'
        case "FOODSTAMPS":
            return (t('payment.type.foodstamps'));
        case "CRYPTO":
            return (t('payment.type.crypto'));
        case "KLARNA":
            return 'Klarna'
        case "GIFTCARD":
            return (t('payment.type.giftcard'));
        default:
            return '';
    }
}