import { FC, useEffect, useMemo, useRef, useState } from 'react';
import DataTable from './DataTable';
import AppAvatar from '../../components/avatars/AppAvatar';
import defaultProfileImage from '../../assets/images/default-profile.png';
import { ContractEntity, DepartmentEntity, EmployeeEntity, EmployeeToSeasonEntityStatusTypeEnum, PaymentEntity, RoomEntity, SeasonEntity, SegmentEntity, StatusEntity } from '../../api/generated';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, Tooltip, MenuItem, Menu, Box, Chip } from '@mui/material';
import { Description, PlaylistAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Small } from '../../components/Typography';
import { employeesApi, seasonApi, statusApi } from '../../api';
import { differenceInDays, format, parseISO } from 'date-fns';
import LocalIconImage from '../../assets/images/excelDownload.png';
import { createReportForUser } from '../../utils/report';
import Calender from '../../icons/duotone/Calender';
import WhatsappIconImage from '../../assets/images/whatsapp-icon.png';
import FlexBox from 'components/flexbox/FlexBox';
import AddStatusModal from './dataTableV2/AddStatusModal';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import '../../assets/css/underLineAnimation.css';
import { useSeason } from 'contexts/SeasonContext';
import getTextColorBasedOnBgColor from 'utils/getTextColorBasedOnBgColor';
import { translateContractType, translateStatus } from 'utils/utils';
import { useEmployeeContext } from 'contexts/EmployeeContext';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignSeasonModal from './dataTableV2/AssignSeasonModal';
import toast, { Toaster } from 'react-hot-toast';

interface DataTableProps {
  data?: any[];
  clearFilter?: string;
  onFilterChange?: (filters: any) => void;
  handleRowSelect: (rows: []) => void;
}

interface RoomWithBuildingName extends RoomEntity {
  building: {
    name: string;
  }
}
interface EmployeeWithBuildingName extends EmployeeEntity {
  room: RoomWithBuildingName;
}


interface EmployeeWithPosition extends EmployeeEntity {
  employeesToDepartments: [
    {
      isDepartmentManager: boolean,
      department: {
        id: number,
        name: string,
        segmentId: number,
        segment: SegmentEntity,
      },
      workPosition: {
        name: string
      }
      contract: ContractEntity
    }
  ]
}

const COLOR_OK = 'success.main';
const COLOR_WARNING = '#ffc675';
const COLOR_URGENT = 'error.main';
const COLOR_NO_PAYMENTS = '#cccccc';
const PERCENTAGE_THRESHOLD_WARNING = 30;
const PERCENTAGE_THRESHOLD_URGENT = 45;

const EmployeesDataTable: FC<DataTableProps> = (props) => {
  const { seasonId } = useSeason();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [statusData, setStatusData] = useState<readonly StatusEntity[]>([
    {
      id: -1,
      name: 'Loading options',
      color: '#00000'
    }
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openAssignSeasonModal, setOpenAssignSeasonModal] = useState(false);
  const [assignableSeasons, setAssigableSeasons] = useState<SeasonEntity[]>();
  const employeeContext = useEmployeeContext();

  const handleClickMore = (event: any, row: any) => {
    const employee = row.original as EmployeeEntity;
    seasonApi.findAllIfNotEmployee(employee.id).then(({ data }) => {
      setAssigableSeasons(data)
    });
    setMoreMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseMore = () => {
    setAssigableSeasons(undefined);
    setMoreMenuAnchor(null);
    setSelectedRow(null);
  };

  const viewEmployee = (row: any) => {
    const employee = row.original as EmployeeEntity;
    employeeContext.setEmployeeId(employee?.id);
    navigate('/dashboard/employees/new', {
      state: {
        employeeId: employee?.id,
      },
    });
  };

  const viewDepartment = (department: DepartmentEntity) => {
    navigate('/dashboard/employees-hierarchy', {
      state: {
        department,
      },
    });
  };


  const fetchUserDataAndCreateReport = (row: any, t: any) => {
    employeesApi
      .findOne(row.original.id.toString(), { params: { includeBankingInformation: true, includeContract: true, includeAgreements: true, includeRoom: true, includeNotes: true, seasonId } })
      .then(async response => {
        const date = format(new Date(), 'dd/MM/yyyy');
        const fileName = `Report_${row.original.firstName}_${row.original.lastName}_${date}.xlsx`;
        await createReportForUser(response.data, fileName, t);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const openCalendar = (row: any) => {
    const employee = row.original as EmployeeEntity;
    navigate('/dashboard/employees/calendar/false', {
      state: {
        employeeId: employee?.id,
      },
    });
  };

  const openNotes = (row: any) => {
    const employee = row.original as EmployeeEntity;
    navigate('/dashboard/employees/new', {
      state: {
        activeTab: 5,
        employeeRole: employee.role,
        employeeId: employee.id,
        employeeName: employee.firstName + ' ' + employee.lastName,
        employeeAvatar: employee.filePath || defaultProfileImage
      },
    });
  };

  const openReport = (row: any) => {
    const employee = row.original as EmployeeEntity;
    navigate('/dashboard/employees/report', {
      state: {
        employeeId: employee.id,
      },
    });
  };

  const handleWhatsAppClick = (phone: string) => {
    const whatsappURL = `https://wa.me/${phone.replace(/\s+/g, "")}`;
    window.open(whatsappURL);
  }

  useEffect(() => {
    fetchStatusData();
  }, []);

  const getPaymentIconColorByDaysDifference = (daysDifference: number) => {
    if (daysDifference <= PERCENTAGE_THRESHOLD_WARNING) {
      return COLOR_OK;
    } else if (daysDifference > PERCENTAGE_THRESHOLD_WARNING && daysDifference <= PERCENTAGE_THRESHOLD_URGENT) {
      return COLOR_WARNING;
    } else {
      return COLOR_URGENT;
    }
  };

  function formatDate(value: string): string {
    const date = new Date(value);
    return date.toLocaleDateString('it-IT');
  }


  const fetchStatusData = () => {
    statusApi.findAll().then(({ data }) => {
      setStatusData([...data]);
    }).catch(error => {
      console.error(error);
    });
  }

  const isIncomplete = (employee: EmployeeEntity) => {

    // this array of strings containts missing data names translated
    const incompleteFields: string[] = [];

    if (!employee.bankingInformation?.iban)
      incompleteFields.push(t('employees.bank.iban'));

    if (!employee.email)
      incompleteFields.push(t('employees.generalInformation.email'));

    if (!employee.phone)
      incompleteFields.push(t('employees.generalInformation.phone'));

    if (!employee.dateOfBirth)
      incompleteFields.push(t('employees.generalInformation.dateOfBirth.label'));

    if (!employee.countryOfBirth)
      incompleteFields.push(t('employees.generalInformation.country'));

    // province and town should be marked as missing only if Italy is selected as country of birth
    if (employee.countryOfBirth === 'IT') {
      if (!employee.provinceOfBirth)
        incompleteFields.push(t('employees.generalInformation.province'));

      if (!employee.townshipOfBirth)
        incompleteFields.push(t('employees.generalInformation.township'));
    }


    return incompleteFields;
  }


  const getColumnShape = (data: any) => {
    return [
      {
        Header: t("employees.generalInformation.statuslabel.name"),
        accessor: (row: any) => row.employeesToSeasons?.at(0)?.statusType === 'CUSTOM' ?
          row.employeesToSeasons?.at(0)?.status?.name.toLowerCase() :
          translateStatus(row.employeesToSeasons?.at(0)?.statusType).name.toLowerCase(),
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          // if the status is not a custom state then translate it
          const firstSeason = employee.employeesToSeasons?.at(0);
          const employeeStatusType = firstSeason?.statusType;

          if (employeeStatusType && employeeStatusType !== 'CUSTOM') {
            const employeeStatus = employeeStatusType || EmployeeToSeasonEntityStatusTypeEnum.Candidate;
            const statusTranslation = translateStatus(employeeStatus);
            const translatedStatus: StatusEntity = {
              id: 0, // default status id
              name: statusTranslation.name,
              color: statusTranslation.color,
            };
            firstSeason.status = translatedStatus;
          }

          const status = firstSeason?.status;
          return status ? (
            <Tooltip title={status.name.toUpperCase()} arrow>
              <Chip label={status.name.toUpperCase()} sx={{ bgcolor: status.color, maxWidth: '100px', width: '100%', color: getTextColorBasedOnBgColor(status.color) }} size='small' />
            </Tooltip>
          ) : '';
        }
      },
      {
        Header: t('employees.generalInformation.name'),
        accessor: (row: any) => `${row.firstName?.toUpperCase()} ${row.lastName?.toUpperCase()}`,
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          const displayAvatar = employee.filePath || defaultProfileImage;

          const [isTall, setIsTall] = useState(false);
          const divRef = useRef<HTMLElement>(null);

          // Check the height of the box to assign the css style  
          useEffect(() => {
            if (divRef.current && divRef.current.offsetHeight > 30) {
              setIsTall(true);
            }
          }, []);
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => { viewEmployee(row) }}
              className={`hover-underline-animation-parent`}
            >
              <AppAvatar src={displayAvatar} />
              <Box className='box' ref={divRef} style={{ marginLeft: '10px' }}>
                <span className={`${isTall ? 'hover-underline-animation' : ''}`}>
                  {`${employee?.firstName} `}
                </span>
                <span className={`${isTall ? 'hover-underline-animation' : ''}`}>
                  {`${employee?.lastName}`}
                </span>
              </Box>
            </Box>

          )
        }
      },
      {
        Header: t('employees.generalInformation.lastPayment'),
        accessor: (row: any) => `${row.lastPayment}`,
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;

          const filterPayments: PaymentEntity[] = (employee.employeesToSeasons?.at(0) as any)?.payments?.filter((payment: PaymentEntity) => {
            return payment.seasonId === seasonId;
          });

          let mostRecentPayment;
          if (filterPayments && filterPayments.length > 0) {
            mostRecentPayment = filterPayments.reduce((latest, current) => {
              const latestDate = new Date(latest.paymentDate);
              const currentDate = new Date(current.paymentDate);
              return latestDate > currentDate ? latest : current;
            });
          }

          const color = mostRecentPayment?.paymentDate ? getPaymentIconColorByDaysDifference(
            differenceInDays(new Date(), parseISO(mostRecentPayment.paymentDate))
          ) : COLOR_NO_PAYMENTS;

          return (
            <Tooltip title={mostRecentPayment?.paymentDate ? formatDate(mostRecentPayment.paymentDate) : t('payment.noPaymentFound')}>
              <div>
                <Small
                  sx={{
                    backgroundColor: color,
                    color: 'background.paper',
                    borderRadius: '50%',
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                  }}
                />
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: t("employees.generalInformation.department.label"),
        accessor: (row: any) => row.employeesToDepartments?.[0]?.department?.name ?? 'Not Assigned',
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeWithPosition;
          const departmentEntity: {
            id: number;
            name: string;
            segmentId: number;
            segment?: SegmentEntity
          } = {
            // The 'Unknown' case should never happen, it's just a precaution to make the code more consistent
            id: employee.employeesToDepartments.at(0)?.department.id ?? -1,
            name: employee.employeesToDepartments.at(0)?.department.name ?? 'Unknown',
            segmentId: employee.employeesToDepartments.at(0)?.department.segmentId ?? -1,
            segment: employee.employeesToDepartments.at(0)?.department.segment ?? undefined,
          };

          // tooltipText is an array to store all departements and his segment of an employee in the format : "segment: departement"
          let tooltipText: any[] = [];
          if (employee.employeesToDepartments.length > 0) {
            employee.employeesToDepartments.forEach((employeeToDepartment) => {
              const departmentName = employeeToDepartment.department.name;
              tooltipText = tooltipText.concat(employeeToDepartment.department.segment ? employeeToDepartment.department.segment.name + ': ' + departmentName : departmentName)
            })
          }
          else {
            tooltipText = tooltipText.concat(t("employees.generalInformation.notAssigned"));
          }
          // const department = employee.employeesToDepartments.length > 0 ? employee.employeesToDepartments.at(0)?.department.name : t("employees.generalInformation.notAssigned")
          return (
            tooltipText.length > 1 ?
              <Tooltip title={
                <>
                  {
                    tooltipText.map((value) => {
                      return (
                        <>
                          {value}
                          <br />
                        </>
                      )
                    }
                    )
                  }
                </>
              }>
                <div>
                  <Box
                    style={{ cursor: employee.employeesToDepartments.length > 0 ? 'pointer' : 'default' }}
                    onClick={() => {
                      if (employee.employeesToDepartments.length > 0) {
                        viewDepartment(departmentEntity)
                      }
                    }}
                    className={`${employee.employeesToDepartments.length > 0 ? 'hover-underline-animation-parent' : ''}`}
                  >
                    <Box className='box'>
                      <span className={`${employee.employeesToDepartments.length > 0 ? 'hover-underline-animation' : ''}`}>
                        {tooltipText.at(0)}
                      </span>
                    </Box>
                  </Box>
                </div>
              </Tooltip> :
              <Box
                style={{ cursor: employee.employeesToDepartments.length > 0 ? 'pointer' : 'default' }}
                onClick={() => {
                  if (employee.employeesToDepartments.length > 0) {
                    viewDepartment(departmentEntity)
                  }
                }}
                className={`${employee.employeesToDepartments.length > 0 ? 'hover-underline-animation-parent' : ''}`}
              >
                <Box className='box'>
                  <span className={`${employee.employeesToDepartments.length > 0 ? 'hover-underline-animation' : ''}`}>
                    {tooltipText.at(0)}
                  </span>
                </Box>
              </Box>
          );
        },
      },
      {
        Header: t("employees.generalInformation.workPosition.label"),
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeWithPosition;
          let workPositions: any[] = []
          if (employee.employeesToDepartments.length > 0) {
            employee.employeesToDepartments.forEach((employeeToDepartment) => {
              workPositions = workPositions.concat(employeeToDepartment.workPosition.name)
            })
          } else {
            workPositions = workPositions.concat(t("employees.generalInformation.notAssigned"))
          }

          return (
            workPositions.length > 1 ?
              <Tooltip title={
                <>
                  {
                    workPositions.map((value) => {
                      return (
                        <>
                          {value}
                          <br />
                        </>
                      )
                    }
                    )
                  }
                </>}>
                <div>
                  <Box className='box'>
                    <span>
                      {workPositions.at(0)}
                    </span>
                  </Box>
                </div>
              </Tooltip> :
              <Box className='box'>
                <span>
                  {workPositions.at(0)}
                </span>
              </Box>
          )
        },
      },
      {
        Header: t('employees.generalInformation.contract'),
        accessor: (row: any) => { return translateContractType(row.employeesToSeasons?.at(0)?.contract?.legalContractType) },
        sortType: 'basic',
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          const StatusChip = () => {
            if (employee?.employeesToSeasons?.at(0)) {
              
              const draftLabel = (employee?.employeesToSeasons[0].contract?.isDraft ? t('employees.contract.draft') + ': ' : '');
              const limitedLabel = draftLabel + t("employees.contract.types.limited");
              const unlimitedLabel = draftLabel + t("employees.contract.types.unlimited");
              const intershipLabel = draftLabel + t("employees.contract.types.internship");
              const apprenticeshipLabel = draftLabel + t("employees.contract.types.apprenticeship");
              const callLabel = draftLabel + t("employees.contract.types.call");
              const defaultLabel = t("employees.generalInformation.notAssigned");

              switch (employee?.employeesToSeasons?.at(0)?.contract?.legalContractType) {
                case 'LIMITED':
                  return <Chip label={limitedLabel} sx={{ bgcolor: '#FFD700', maxWidth: '150px', width: '100%', color: getTextColorBasedOnBgColor('#FFD700') }} size='small' />
                case 'UNLIMITED':
                  return <Chip label={unlimitedLabel} sx={{ bgcolor: '#DE3163', maxWidth: '150px', width: '100%', color: getTextColorBasedOnBgColor('#DE3163') }} size='small' />
                case 'INTERNSHIP':
                  return <Chip label={intershipLabel} sx={{ bgcolor: '#7FFFD4', maxWidth: '150px', width: '100%', color: getTextColorBasedOnBgColor('#7FFFD4') }} size='small' />
                case 'APPRENTICESHIP':
                  return <Chip label={apprenticeshipLabel} sx={{ bgcolor: '#DA70D6', maxWidth: '150px', width: '100%', color: getTextColorBasedOnBgColor('#DA70D6') }} size='small' />
                case 'CALL':
                  return <Chip label={callLabel} sx={{ bgcolor: '#3CB371', maxWidth: '150px', width: '100%', color: getTextColorBasedOnBgColor('#3CB371') }} size='small' />
                default:
                  return <Chip label={defaultLabel} sx={{ maxWidth: '150px', width: '100%' }} size='small' />
              }
            }
            else
              return (<></>)
          }

          let startDate = '  -';
          let endDate = '  -';

          if (employee?.employeesToSeasons?.at(0)?.contract?.startedOn) {
            const startDateValue = employee?.employeesToSeasons?.at(0)?.contract?.startedOn;
            if (startDateValue) {
              const startDateFormat = new Date(startDateValue);
              startDate = startDateFormat.toLocaleDateString('it-IT');
            }
          }

          if (employee?.employeesToSeasons?.at(0)?.contract?.endedOn) {
            const endDateValue = employee?.employeesToSeasons?.at(0)?.contract?.endedOn;
            if (endDateValue) {
              const endDateFormat = new Date(endDateValue);
              endDate = endDateFormat.toLocaleDateString('it-IT');
            }
          }

          return (
            <Tooltip title={
              <span>
                {t('start.contract') + " " + startDate}
                {endDate !== '  -' && <><br />{t('end.contract') + " " + endDate}</>}
              </span>
            }>
              <div>
                <StatusChip />
              </div>
            </Tooltip>)

        },
      },
      {
        Header: t('housing.collectionName'),
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeWithBuildingName;
          const MAX_WIDTH_IN_PIXELS = 100;
          const buildingName = employee?.room?.building?.name;
          const roomName = employee?.room?.name;

          const tooltipContent = (
            <>
              {buildingName}
              <br />
              {roomName}
            </>
          );

          const displayRoomAndBuilding = (() => {
            if (buildingName && roomName) {
              return (
                <>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: `${MAX_WIDTH_IN_PIXELS}px` }}>
                    {`${buildingName}`}
                  </div>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: `${MAX_WIDTH_IN_PIXELS}px` }}>
                    {`${roomName}`}
                  </div>
                </>
              );
            } else {
              return '';
            }

          })
          return (
            <FlexBox>
              <Tooltip title={tooltipContent} arrow placement='top-end'>
                <div>
                  {displayRoomAndBuilding()}
                </div>
              </Tooltip>
            </FlexBox>
          );
        },
      },
      {
        Header: '✓',
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          const incompelteFields = isIncomplete(employee);
          return incompelteFields.length > 0 ? (
            <Tooltip title={
              <>
                <div>{t('employees.generalInformation.status.incompleteFields')}: </div>
                {
                  // map fields to show theme as a vertical list
                  incompelteFields.map((field) => {
                    return <div key={field}> •  {field} </div>
                  })
                }

              </>



            }>
              <div>
                <Small
                  sx={{
                    backgroundColor: 'warning.main',
                    color: 'background.paper',
                    borderRadius: '50%',
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                  }}
                />
              </div>
            </Tooltip>

          ) : (
            <Small
              sx={{
                backgroundColor: 'success.main',
                color: 'background.paper',
                borderRadius: '50%',
                width: '1rem',
                height: '1rem',
                display: 'flex',
              }}
            />
          );
        },
      },
      {
        Header: 'Chat',
        Cell: ({ row }: any) => {
          const isPhoneAvailable = row.original.phone;
          return (
            <IconButton
              onClick={() => {
                if (isPhoneAvailable) {
                  handleWhatsAppClick(row.original.phone);
                }
              }}
              style={{ cursor: isPhoneAvailable ? 'pointer' : 'default' }}
              disabled={!isPhoneAvailable}
            >
              <Icon
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '18px',
                  height: '18px',
                }}>
                <img src={WhatsappIconImage} alt="Whatsapp Icon" style={{ width: '30px', height: '20px', filter: isPhoneAvailable ? 'none' : 'grayscale(100%)', }} /></Icon>
            </IconButton>
          );
        },
      },
      {
        Header: t('notes.collectionName'),
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          const isNotesAvailable = Boolean(employee.id);
          return (
            <Tooltip title={employee.notes?.[employee.notes.length - 1]?.text ?? ''}>
              <IconButton
                onClick={() => { openNotes(row) }}
                style={{ cursor: isNotesAvailable ? 'pointer' : 'default' }}
                disabled={!isNotesAvailable}
              >
                <Description sx={{ color: 'primary.main', fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        Header: t('mainTable.more'),
        Cell: ({ row }: any) => {
          return (
            <IconButton onClick={(event) => { handleClickMore(event, row) }}>
              <MoreHorizIcon fontSize="small" />
            </IconButton>
          );
        },
      },
    ];
  };
  const columns: any = useMemo(() => getColumnShape(props.data), [t, statusData, seasonId]);


  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <AssignSeasonModal
        open={openAssignSeasonModal && assignableSeasons !== undefined && assignableSeasons.length > 0}
        onClose={() => { setOpenAssignSeasonModal(false); handleCloseMore(); }}
        seasons={assignableSeasons}
        row={selectedRow}
        confirmToast={() => { toast.success(t('assignSeason.completed')) }}
      />
      <AddStatusModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        fetchData={fetchStatusData}
      />
      {selectedRow && (
        <Menu
          anchorEl={moreMenuAnchor}
          open={Boolean(moreMenuAnchor)}
          onClose={() => {
            handleCloseMore();
          }}
        >

          <MenuItem
            onClick={() => { viewEmployee(selectedRow) }}
            sx={{
              width: '220px',
            }}
          >
            <span style={{ fontSize: '14px' }}>{t('common.tables.button.view')}</span>
            <VisibilityIcon sx={{ color: 'text.disabled', fontSize: 16, marginLeft: 'auto' }} />
          </MenuItem>

          <MenuItem
            onClick={() => { openCalendar(selectedRow) }}
          >
            <span style={{ fontSize: '14px' }}>{t('employees.calendar.openCalendar')}</span>
            <Calender sx={{ color: 'text.disabled', fontSize: 14, marginLeft: 'auto' }} />
          </MenuItem>



          <MenuItem
            onClick={() => { openReport(selectedRow) }}
          >
            <span style={{ fontSize: '14px' }}>{t('report.reportname')}</span>
            <AssessmentIcon sx={{ color: 'text.disabled', fontSize: 16, marginLeft: 'auto' }} />
          </MenuItem>

          <MenuItem
            onClick={() => { fetchUserDataAndCreateReport(selectedRow, t); }}
          >
            <span style={{ fontSize: '14px' }}>{t('report.name')}</span>
            <Icon
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 16,
                marginLeft: 'auto'
              }}>
              <img src={LocalIconImage} alt="Excel Icon" style={{ width: '28px', height: '18px' }} />
            </Icon>
          </MenuItem>
          {assignableSeasons !== undefined && assignableSeasons.length > 0 && <MenuItem onClick={() => { setOpenAssignSeasonModal(true) }}>
            <span style={{ fontSize: '14px' }}>{t('assignSeason.name')}</span>
            <PlaylistAdd sx={{ color: 'text.disabled', fontSize: 16, marginLeft: 'auto' }} />
          </MenuItem>}
        </Menu>
      )}
      {DataTable({
        ...props,
        columns,
      })}
    </>
  );
};

export default EmployeesDataTable;