import { FC, useEffect, useState } from "react";
import { DailyNoteEntity, DepartmentEntity, DepositEntity, PaymentMethodEntity, SectionEntity, TurnoverItemEntity } from "api/generated";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, useTheme, Tooltip, Box, useMediaQuery } from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowDown, Savings, AttachMoney, EditNote } from "@mui/icons-material";
import { ignoreUTC, searchByName } from "utils/utils";
import { t } from "i18next";
import DailyNotesModal from "./dataTableV2/DailyNotesModal";
import { addDays, format, isAfter, isBefore, isSameDay } from "date-fns";
import { translatePaymentTypes } from "utils/convertPaymentTypes";

interface DataTableProps {
    startDate: Date;
    endDate: Date;
    turnoverItems?: TurnoverItemEntity[];
    departments?: DepartmentEntity[];
    sections?: SectionEntity[];
    paymentMethods?: PaymentMethodEntity[];
    deposits?: DepositEntity[];
    dailyNotes: DailyNoteEntity[];
    searchValue: string;
    showEmpty: boolean
    fetchData: () => void;
    openDepositModal: (date: Date) => void;
}


const TurnoverDataTable: FC<DataTableProps> = ({
    startDate,
    endDate,
    turnoverItems,
    departments,
    sections,
    deposits,
    searchValue,
    dailyNotes,
    fetchData,
    paymentMethods,
    showEmpty,
    openDepositModal
}) => {

    const moneyFormatter = Intl.NumberFormat('de-DE', {
        currency: 'EUR',
        currencyDisplay: 'symbol',
        currencySign: 'standard',
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
    });

    // const { seasonId } = useSeason();
    // const employeeId = checkToken();

    const getDates = (start: Date, end: Date) => {
        if (start <= end) {
            const dates: Date[] = []
            let date = new Date(start);
            while (date.getTime() <= end.getTime()) {
                dates.push(date)
                date = addDays(date, 1);
            }
            return dates;
        }
        return [];
    };

    const [dates, setDates] = useState<Date[]>([]);

    useEffect(() => {
        setDates(getDates(startDate, endDate));
    }, [startDate, endDate]);

    const generateRows = async () => {
        const rows: any[] = [];
        if (turnoverItems && sections && departments) {
            let id = 0;

            const depositsRow: any = {
                isDeposit: true,
                name: t('deposits'),
                id,
                empty: false,
                total: 0
            };
            id = id + 1;

            const turnoversRow: any = {
                isTurnover: true,
                name: t('menu.apps.turnover'),
                id,
                empty: false,
                total: 0
            };
            id = id + 1;

            if (deposits) {
                for (const date of dates) {
                    if (!depositsRow[ignoreUTC(date).toISOString()]) {
                        depositsRow[ignoreUTC(date).toISOString()] = 0;
                    }
                    const dateDeposits = deposits.filter((deposit) => {
                        return isSameDay(new Date(deposit.date), date);
                    });
                    for (const deposit of dateDeposits) {
                        depositsRow[ignoreUTC(date).toISOString()] = depositsRow[ignoreUTC(date).toISOString()] + deposit.value;
                        depositsRow.total = depositsRow.total + deposit.value;
                    }
                }
                rows.push(depositsRow);
            }

            for (const department of departments) {
                const departmentRow: any = {
                    isDepartment: true,
                    isSection: false,
                    department,
                    name: department.name,
                    id,
                    empty: true,
                    sectionIds: [],
                    total: 0
                };
                id = id + 1;
                for (const section of sections) {
                    if (section.departmentId === department.id) {
                        const sectionRow: any = {
                            isDepartment: false,
                            isSection: true,
                            department,
                            section,
                            name: section.name,
                            id,
                            empty: true,
                            methodIds: [],
                            total: 0
                        };
                        id = id + 1;

                        const turnoverFromDeletedMethods = turnoverItems.filter(item => {
                            const date = ignoreUTC(new Date(item.date));
                            return item.paymentSectionId === section.id && 
                            !section.paymentMethods?.find(method => method.id === item.paymentMethodId) &&
                            isAfter(date, startDate) && isBefore(date, endDate);
                            ;
                        });

                        const deletedMethods: PaymentMethodEntity[] = [];


                        turnoverFromDeletedMethods.forEach(turnover => {
                            if (!section.paymentMethods?.find(method => method.id === turnover.paymentMethodId)) {
                                const newMethod = paymentMethods?.find(method => method.id === turnover.paymentMethodId);
                                if (newMethod)
                                    deletedMethods.push(newMethod);
                            }

                        })

                        for (const method of (section.paymentMethods ?? []).concat(deletedMethods)) {
                            const methodRow: any = {
                                isDepartment: false,
                                isSection: false,
                                department,
                                section,
                                method,
                                name: translatePaymentTypes(method.type, t),
                                id,
                                editable: true,
                                empty: true,
                                total: 0
                            };
                            id = id + 1;
                            for (const date of dates) {
                                if (!sectionRow[ignoreUTC(date).toISOString()]) {
                                    sectionRow[ignoreUTC(date).toISOString()] = 0;
                                }
                                if (!departmentRow[ignoreUTC(date).toISOString()]) {
                                    departmentRow[ignoreUTC(date).toISOString()] = 0;
                                }
                                if (!turnoversRow[ignoreUTC(date).toISOString()]) {
                                    turnoversRow[ignoreUTC(date).toISOString()] = 0;
                                }
                                const items = turnoverItems.filter((item) => {
                                    return (
                                        isSameDay(new Date(item.date), date) &&
                                        item.paymentSectionId === section.id &&
                                        item.paymentMethodId === method.id
                                    )
                                });

                                if (items.length > 0) {
                                    const sum = items.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)

                                    if (!departmentRow.sectionIds.includes(section.id)) {
                                        departmentRow.sectionIds.push(section.id);
                                    }
                                    if (!sectionRow.methodIds.includes(method.id)) {
                                        sectionRow.methodIds.push(method.id);
                                    }
                                    methodRow.empty = false;
                                    sectionRow.empty = false;
                                    departmentRow.empty = false;
                                    methodRow[ignoreUTC(date).toISOString()] = sum;
                                    methodRow.total = methodRow.total + sum;
                                    sectionRow[ignoreUTC(date).toISOString()] = sectionRow[ignoreUTC(date).toISOString()] + sum;
                                    sectionRow.total = sectionRow.total + sum;
                                    departmentRow[ignoreUTC(date).toISOString()] = departmentRow[ignoreUTC(date).toISOString()] + sum;
                                    departmentRow.total = departmentRow.total + sum;
                                    turnoversRow[ignoreUTC(date).toISOString()] = turnoversRow[ignoreUTC(date).toISOString()] + sum;
                                    turnoversRow.total = turnoversRow.total + sum;
                                }
                            }
                            rows.push(methodRow);
                        }
                        rows.push(sectionRow);
                    }
                }
                rows.push(departmentRow);
            }
            rows.push(turnoversRow);
            return rows.sort((a, b) => {
                return a.id - b.id;
            });
        }
        return [];
    }

    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        generateRows().then((rows) => {
            setRows(rows)
        })
    }, [deposits, departments, sections, turnoverItems, dates, paymentMethods]);

    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    useEffect(() => {
        if (departments && sections && paymentMethods) {
            const filteredDepartments: DepartmentEntity[] = searchByName(departments, searchValue);
            const filteredSections: SectionEntity[] = searchByName(sections, searchValue);
            // this is needed to use searchByName using translated payment name
            const filteredMethods = searchByName(paymentMethods.map(
                method => {
                    return {
                        name: translatePaymentTypes(method.type, t),
                        id: method.id
                    }
                }
            ), searchValue);

            const result = rows.filter((row) => {
                return (
                    row.isDeposit || row.isTurnover || (
                        row.section &&
                        filteredSections.find((section) => { return section.id === row.section.id })
                    ) || (
                        row.department &&
                        filteredDepartments.find((department) => { return department.id === row.department.id })
                    ) || (
                        row.method &&
                        filteredMethods.find((method) => { return method.id === row.method.id })
                    ) || (
                        row.isDepartments && filteredSections.find((section) => { return row.sectionIds.includes(section.id) })
                    ) || (
                        row.isSection &&
                        filteredMethods.length > 0 &&
                        (
                            showEmpty ||
                            !rows.find((r) => {
                                return (
                                    !r.isTurnover &&
                                    !r.isDeposit &&
                                    !r.isDepartment &&
                                    !r.isSection &&
                                    r.section.id === row.section?.id &&
                                    filteredMethods.find((fm) => { return fm.id === r.method.id })
                                )
                            })?.empty
                        )
                    ) ||
                    (
                        row.isDepartment &&
                        filteredMethods.length > 0 &&
                        (
                            showEmpty ||
                            !rows.find((r) => {
                                return (
                                    !r.isTurnover &&
                                    !r.isDeposit &&
                                    !r.isDepartment &&
                                    !r.isSection &&
                                    r.department.id === row.department?.id &&
                                    filteredMethods.find((fm) => { return fm.id === r.method.id })
                                )
                            })?.empty
                        )
                    )
                )
            })

            setFilteredRows(result);
        }
    }, [rows, departments, sections, paymentMethods, searchValue, showEmpty])

    const [expandedDepartments, setExpandedDepartments] = useState<Array<{
        departmentId: number;
        expanded: boolean;
    }>>([]);

    const [expandedSections, setExpandedSections] = useState<Array<{
        sectionId: number;
        expanded: boolean;
    }>>([]);

    useEffect(() => {
        const updatedSections = sections
            ? sections.map((section) => {
                // Trova la sezione esistente con lo stesso sectionId
                const existingSection = expandedSections.find(
                    (s) => s.sectionId === section.id
                );

                // Se esiste, mantiene il valore di 'expanded', altrimenti lo imposta a false
                return existingSection ?? { sectionId: section.id, expanded: false };
            })
            : [];

        setExpandedSections(updatedSections);
    }, [sections]);

    useEffect(() => {
        const updatedDepartments = departments
            ? departments.map((department) => {
                // Trova la sezione esistente con lo stesso sectionId
                const existingDepartment = expandedDepartments.find(
                    (d) => d.departmentId === department.id
                );

                // Se esiste, mantiene il valore di 'expanded', altrimenti lo imposta a false
                return existingDepartment ?? { departmentId: department.id, expanded: false };
            })
            : [];

        setExpandedDepartments(updatedDepartments);
    }, [departments]);

    const isRowToShow = (row: any) => {
        if (row.isDepartment || row.isDeposit || row.isTurnover) {
            return true;
        } else if (row.isSection) {
            return expandedDepartments.find((department) => {
                return department.departmentId === row.department.id
            })?.expanded
        } else {
            return expandedSections.find((section) => {
                return section.sectionId === row.section.id
            })?.expanded
        }
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const [selectedCell, setSelectedCell] = useState<{ rowId: number | null, date: string | null }>({ rowId: null, date: null });

    const [openDailyNotesModal, setOpenDailyNotesModal] = useState(false);
    const [selectedNotes, setSelectedNotes] = useState<DailyNoteEntity[]>([]);
    const [selectedNotesDate, setSelectedNotesDate] = useState<Date>();

    const handleOpenNotesModal = (date: Date) => {
        setOpenDailyNotesModal(true);
        setSelectedNotesDate(date);
    }

    useEffect(() => {
        if (selectedNotesDate) {
            const dateNotes = dailyNotes.filter((note) => {
                return isSameDay(new Date(note.date), selectedNotesDate);
            })
            setSelectedNotes(dateNotes);
        } else {
            setSelectedNotes([]);
        }
    }, [selectedNotesDate, dailyNotes])

    return (
        <>
            <DailyNotesModal
                open={openDailyNotesModal}
                onClose={() => {
                    setOpenDailyNotesModal(false);
                    setSelectedNotes([]);
                }}
                notes={selectedNotes}
                fetchData={fetchData}
                date={selectedNotesDate}
            />
            <TableContainer
                sx={{
                    borderRadius: '12px',
                    overflow: 'hidden',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    overflowX: 'auto',
                }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                sx={{
                                    width: 56,
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 1,
                                    borderBottom: '5px solid #ddd',
                                    backgroundColor: theme.palette.background.default,
                                    opacity: 1,
                                }}>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    minWidth: isMobile ? 150 : 250,
                                    position: 'sticky',
                                    left: 56,
                                    zIndex: 1,
                                    borderBottom: '5px solid #ddd',
                                    backgroundColor: theme.palette.background.default,
                                    opacity: 1,

                                }}>

                            </TableCell>
                            {
                                dates.map((date) => (
                                    <TableCell
                                        align="center"
                                        sx={{
                                            minWidth: 150,
                                            borderBottom: '5px solid #ddd',
                                            backgroundColor: dailyNotes.find((note) => {
                                                return isSameDay(new Date(note.date), date);
                                            }) ? "#fdebd0" : ""
                                        }}
                                    >
                                        {format(date, 'dd/MM')}
                                        <IconButton onClick={() => {
                                            handleOpenNotesModal(ignoreUTC(date));
                                        }}>
                                            <EditNote />
                                        </IconButton>

                                    </TableCell>
                                ))
                            }
                            <TableCell
                                align="center"
                                sx={{
                                    minWidth: 150,
                                    position: isMobile ? 'static' : 'sticky',
                                    right: isMobile ? 'auto ' : 0,
                                    zIndex: 1,
                                    borderBottom: '5px solid #ddd',
                                    backgroundColor: theme.palette.background.default,
                                    opacity: 1,

                                }}
                            >
                                {t('total')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((row) => (
                            isRowToShow(row) && (showEmpty || !showEmpty && !row.empty) && <TableRow
                                key={row.id}
                            >
                                <TableCell
                                    align="center"
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        alignItems: 'left',
                                        borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                        borderBottom: row.isTurnover ? '5px solid #ddd' : '1px solid #ddd',
                                        backgroundColor:
                                            row.isDeposit || row.isTurnover ? ' #ebf5fb ' :
                                                row.isSection ? '#d6eaf8' :
                                                    row.isDepartment ? theme.palette.background.default :
                                                        /* selectedCell.rowId && selectedCell.date &&
                                                            selectedCell.rowId === row.id &&
                                                            selectedCell.date === ignoreUTC(date).toISOString() ? '#85c1e9' : */
                                                        '#aed6f1',
                                        opacity: 1,

                                    }}
                                >
                                    {
                                        row.isTurnover && <AttachMoney sx={{ minWidth: 55 }} />
                                    }
                                    {
                                        row.isDeposit && <Savings sx={{ minWidth: 55 }} />
                                    }
                                    {
                                        row.isDepartment && row.department && <IconButton
                                            onClick={() => {
                                                // If a department is closed its sections should also be closed
                                                if (
                                                    expandedDepartments.find((expandedDepartment) => {
                                                        return expandedDepartment.departmentId === row.department.id
                                                    })?.expanded
                                                ) {
                                                    setExpandedSections((prevSections) =>
                                                        prevSections.map((section) =>
                                                            // Search in rows the section and return true if his depId === row.department.id
                                                            rows.find((row) => {
                                                                return row.isSection && row.section.id === section.sectionId
                                                            }).department.id === row.department.id ?
                                                                { ...section, expanded: false } :
                                                                section
                                                        )
                                                    );
                                                }

                                                //
                                                setExpandedDepartments((prevDepartments) =>
                                                    prevDepartments.map((department) =>
                                                        department.departmentId === row.department.id
                                                            ? { ...department, expanded: !department.expanded }
                                                            : department
                                                    )
                                                );
                                            }}>
                                            {expandedDepartments.find((expandedDepartment) => {
                                                return expandedDepartment.departmentId === row.department.id
                                            })?.expanded ?
                                                <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                        </IconButton>
                                    }
                                    {
                                        row.isSection && row.section && <IconButton
                                            onClick={() => {
                                                setExpandedSections((prevSections) =>
                                                    prevSections.map((section) =>
                                                        section.sectionId === row.section.id
                                                            ? { ...section, expanded: !section.expanded }
                                                            : section
                                                    )
                                                );
                                            }}>
                                            {expandedSections.find((expandedSection) => {
                                                return expandedSection.sectionId === row.section.id
                                            })?.expanded ?
                                                <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                        </IconButton>
                                    }
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        paddingLeft: () => {
                                            if (isMobile) {
                                                return 0;
                                            } else {
                                                if (row.isDepartment || row.isDeposit || row.isTurnover) {
                                                    return 0;
                                                } else if (row.isSection) {
                                                    return 5;
                                                } else {
                                                    return 10;
                                                }
                                            }
                                        },
                                        minWidth: isMobile ? 150 : 250,
                                        position: 'sticky',
                                        left: 56,
                                        zIndex: 1,
                                        borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                        borderBottom: row.isTurnover ? '5px solid #ddd' : '1px solid #ddd',
                                        backgroundColor:
                                            row.isDeposit || row.isTurnover ? ' #ebf5fb ' :
                                                row.isSection ? '#d6eaf8' :
                                                    row.isDepartment ? theme.palette.background.default :
                                                        ' #aed6f1 ',
                                        opacity: 1,

                                    }}
                                >
                                    {row.name}
                                </TableCell>
                                {
                                    dates.map((date) => (
                                        <TableCell
                                            align="center"
                                            sx={{
                                                minWidth: 150,
                                                borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                                borderBottom: row.isTurnover ? '5px solid #ddd' : '1px solid #ddd',
                                                backgroundColor:
                                                    row.isDeposit || row.isTurnover ? ' #ebf5fb ' :
                                                        row.isSection ? '#d6eaf8' :
                                                            row.isDepartment ? theme.palette.background.default :
                                                                /* selectedCell.rowId && selectedCell.date &&
                                                                    selectedCell.rowId === row.id &&
                                                                    selectedCell.date === ignoreUTC(date).toISOString() ? '#85c1e9' : */
                                                                '#aed6f1',
                                                opacity: 1,
                                            }}
                                            onClick={() => {
                                                if (row.isDeposit) {
                                                    openDepositModal(date);
                                                }
                                            }}
                                        >
                                            {
                                                row.isTurnover || row.isSection || row.isDepartment ? <>
                                                    {moneyFormatter.format((row[ignoreUTC(date).toISOString()] ?? 0.00).toFixed(2))}
                                                </> :
                                                    row.isDeposit ? <Tooltip title={t('edit')}>
                                                        <Box>
                                                            {moneyFormatter.format((row[ignoreUTC(date).toISOString()] ?? 0.00).toFixed(2))}
                                                        </Box>
                                                    </Tooltip> : <>{moneyFormatter.format((row[ignoreUTC(date).toISOString()] ?? 0.00).toFixed(2))}</>
                                            }
                                        </TableCell>
                                    ))
                                }
                                < TableCell
                                    align="center"
                                    sx={{
                                        minWidth: 150,
                                        position: isMobile ? 'static' : 'sticky',
                                        right: isMobile ? 'auto ' : 0,
                                        zIndex: 1,
                                        borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                        borderBottom: row.isTurnover ? '5px solid #ddd' : '1px solid #ddd',
                                        backgroundColor:
                                            row.isDeposit || row.isTurnover ? ' #ebf5fb ' :
                                                row.isSection ? '#d6eaf8' :
                                                    row.isDepartment ? theme.palette.background.default :
                                                        ' #aed6f1 ',
                                        opacity: 1,

                                    }}
                                >
                                    {moneyFormatter.format(row.total ?? 0)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table >
            </TableContainer >
        </>
    );
}

export default TurnoverDataTable;